.cron_builder_bordering {
  border: 1px solid #ddd;
  border-top: none;
  text-align: center;
  padding: 10px;
  background: #fff;
}
.cron_builder_bordering input, .cron_builder_bordering select {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  padding: 0px 5px;
  min-height: 28px;
}
.df {
  display: flex;
}
.cron-builder-bg {
  background-color: #086090;
  color: white;
  text-align: center;
  margin-bottom: 4px;
  padding: 8px 0px;
}
.cron_builder_bordering select {
  background-color: white;
  width: 75px;
  cursor: pointer;
  padding: 4px 2px;
  border-radius: 4px;
}
.cron_builder_bordering select option:hover {
  background-color: #086090;
}
.well-small input {
  width: auto !important;
}
.cron_builder_bordering  input[type='radio'] {
  margin-top: 0px;
  vertical-align: middle;
}
.cron_builder {
  border: 1px solid #d0cbcb;
  padding: 5px;
  background-color: #dddef13d;
  width: 100%;
  max-width: 600px;
}
.text_align_left {
  text-align: left;
}
.cron_builder .nav li {
  cursor: pointer;
  flex: 1 1 10px;
  text-align: center;
  width: 10px;
  display: flex;
  padding: 0px 1px;
}
.cron_builder .nav li a {
  color:#337ab7;
  width: 100%;
  padding: 10px;
  display: inline-block;
  border-radius: 4px 4px 0px 0px;
}
.cron_builder .nav-tabs .nav-link:focus, .cron_builder .nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
  background-color: #eeeeee;
}
.cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active {
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #ffffff;
}
.cron_builder { 
  font-size: 14px;
}
.cron_builder .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
@media screen and (max-width:767px) {
  .cron_builder .nav li {
      cursor: pointer;
      flex: 0 0 65px;
      text-align: center;
  }
}

/* ---- boostrap ----- */
.nav.nav-tabs {
  list-style: none;
  display: flex;
  margin: 0 0;
  padding-left: 0;
}
.row {
  display: flex;
}
.col-sm-6 {
  flex: 0 0 50%;
}
.min_height_auto {
  min-height: auto !important;
}
body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
